// Logos
import {
  citymapper,
  citymapperColor,
  citymapperColorOneline,
  citymapperWhite,
  mindspace,
  monzo,
  monzoColor,
  monzoWhite,
  oddbox,
  shutterstock,
  starlingColor,
  taskrabbit,
  fora,
  landmark,
  secondHome,
  tog,
  togBlue,
  trustpilot,
  trustpilotWhite,
  uncommon,
  worklife,
} from 'site-react/assets';

/**
 * We're duplicating a lot of assets in places by scoping these company
 * logos to this feature, so over time we should aim to move these into
 * src/assets/logos so that they can be used across the site.
 */
import abelAndCole from './logos/abel&cole.svg';
import abelAndColeBlack from './logos/abelAndColeBlack.svg';
import accentureColor from './logos/accenture-color.svg';
import accentureWhite from './logos/Accenture-white.svg';
import accentureLogo from './logos/accenture.svg';
import breather from './logos/breather.svg';
import brollyLogo from './logos/brolly-logo.svg';
import brollyWhite from './logos/brolly-white.svg';
import downingVenturesLogo from './logos/downing-ventures.svg';
import duedilWhite from './logos/duedil-white.svg';
import efLogo from './logos/ef-logo.svg';
import forbesLogo from './logos/Forbes.svg';
import foxWhite from './logos/fox-white.svg';
import fox from './logos/fox.svg';
import financialTimesLogo from './logos/FT.svg';
import fundingCircleWhite from './logos/funding-circle-white.svg';
import greatPortlandEstates from './logos/great-portland-estates.svg';
import growBiotechWhite from './logos/Grow-Biotech-logo-white.svg';
import helloFresh from './logos/hello-fresh.svg';
import houzzWhite from './logos/houzz-white.svg';
import huckletree from './logos/huckletree.svg';
import jaguarlandroverWhite from './logos/jaguarlandrover-white.svg';
import jaguarlandrover from './logos/jaguarlandrover.svg';
import jllLogo from './logos/jll.svg';
import knotel from './logos/knotel.svg';
import kpmgWhite from './logos/KPMG-white.svg';
import landRover from './logos/land-rover.svg';
import legalAndGeneral from './logos/legal-and-general.svg';
import liverampWhite from './logos/liveramp-white.svg';
import londonSock from './logos/london-sock.svg';
import moveable from './logos/moveable.svg';
import nhsColor from './logos/nhs-color.svg';
import nhsWhite from './logos/nhs-white.svg';
import nhs from './logos/nhs.svg';
import oneFineStayWhite from './logos/onefinestay-white.svg';
import onfidoWhite from './logos/onfido-white.svg';
import otro from './logos/otro.svg';
import piTop from './logos/pi-top.svg';
import pilabsLogo from './logos/pilabs-logo.svg';
import priceWaterHouseCoopersColor from './logos/PricewaterhouseCoopers-color.svg';
import priceWaterHouseCoopersWhite from './logos/PricewaterhouseCoopers-white.svg';
import proper from './logos/proper.svg';
import scgLogo from './logos/scg-logo.svg';
import seedcampLogo from './logos/seedcamp.svg';
import songkickWhite from './logos/songkick-white.svg';
import songkick from './logos/songkick.svg';
import stubhubWhite from './logos/stubhub-white.svg';
import stubhub from './logos/stubhub.svg';
import techCrunchLogo from './logos/TechCrunch_logo.svg';
import telefonicaWhite from './logos/telefonica-white.svg';
import telefonica from './logos/telefonica.svg';
import theCrownEstate from './logos/the-crown-estate.svg';
import guardianLogo from './logos/The_Guardian.svg';
import telegraphLogo from './logos/The_Telegraph_logo.svg';
import tobacoDock from './logos/tobaco-dock.svg';
import trussle from './logos/trussle.svg';
import trustpilotBlack from './logos/trustpilot.svg';
import weWork from './logos/we-work.svg';
import wiredLogo from './logos/Wired_logo.svg';
import workspace from './logos/workspace.svg';
import wppWhite from './logos/WPP-logo-white.svg';
import wpp from './logos/wpp.svg';

export default {
  abelAndCole: {
    alt: 'Abel&Cole',
    src: abelAndCole,
  },
  abelAndColeBlack: {
    alt: 'Abel&Cole',
    src: abelAndColeBlack,
  },
  accenture: {
    alt: 'Accenture',
    src: accentureLogo,
    width: 121,
  },
  accentureColor: {
    alt: 'Accenture',
    src: accentureColor,
    width: 121,
  },
  accentureWhite: {
    alt: 'Accenture',
    src: accentureWhite,
  },
  breather: {
    alt: 'Breather',
    src: breather,
    width: 120,
  },
  brollyLogo: {
    alt: 'Brolly',
    src: brollyLogo,
  },
  brollyWhite: {
    alt: 'Brolly',
    src: brollyWhite,
  },
  citymapper: {
    alt: 'Citymapper',
    src: citymapper,
    width: 106,
  },
  citymapperColor: {
    alt: 'Citymapper',
    src: citymapperColor,
    width: 106,
  },
  citymapperColorOneline: {
    alt: 'Citymapper',
    src: citymapperColorOneline,
  },
  citymapperWhite: {
    alt: 'Citymapper',
    src: citymapperWhite,
  },
  downingVentures: {
    alt: 'Downing Ventures',
    src: downingVenturesLogo,
    width: 126,
  },
  duedilWhite: {
    alt: 'DueDil',
    src: duedilWhite,
  },
  ef: {
    alt: 'EF',
    src: efLogo,
    width: 100,
  },
  financialTimes: {
    alt: 'Financial Times',
    src: financialTimesLogo,
    width: 51,
  },
  fora: {
    alt: 'Fora',
    src: fora,
    width: 107,
  },
  forbes: {
    alt: 'Forbes',
    src: forbesLogo,
    width: 107,
  },
  fox: {
    alt: 'Fox',
    src: fox,
    width: 70,
  },
  foxWhite: {
    alt: 'Fox',
    src: foxWhite,
  },
  fundingCircleWhite: {
    alt: 'Funding Circle',
    src: fundingCircleWhite,
  },
  greatPortlandEstates: {
    alt: 'Great Portland Estates',
    src: greatPortlandEstates,
  },
  growBiotechWhite: {
    alt: 'Grow Biotech',
    src: growBiotechWhite,
  },
  guardian: {
    alt: 'The Guardian',
    src: guardianLogo,
    width: 122,
  },
  helloFresh: {
    alt: 'HelloFresh',
    src: helloFresh,
    width: 115,
  },
  houzzWhite: {
    alt: 'Houzz',
    src: houzzWhite,
  },
  huckletree: {
    alt: 'Huckletree',
    src: huckletree,
    width: 110,
  },
  jaguarlandrover: {
    alt: 'Jaguar Land Rover',
    src: jaguarlandrover,
    width: 174,
  },
  jaguarlandroverWhite: {
    alt: 'Jaguar Land Rover',
    src: jaguarlandroverWhite,
    width: 174,
  },
  jll: {
    alt: 'JLL',
    src: jllLogo,
    width: 120,
  },
  knotel: {
    alt: 'Knotel',
    src: knotel,
    width: 120,
  },
  kpmgWhite: {
    alt: 'KPMG',
    src: kpmgWhite,
  },
  landmark: {
    alt: 'Landmark',
    src: landmark,
  },
  landRover: {
    alt: 'Land Rover',
    src: landRover,
  },
  legalAndGeneral: {
    alt: 'Legal and General',
    src: legalAndGeneral,
    width: 110,
  },
  liverampWhite: {
    alt: 'LiveRamp',
    src: liverampWhite,
  },
  londonSock: {
    alt: 'London Sock',
    src: londonSock,
  },

  mindspace: {
    alt: 'Mindspace',
    src: mindspace,
    width: 85,
  },
  monzo: {
    alt: 'Monzo',
    src: monzo,
    width: 55,
  },
  monzoColor: {
    alt: 'Monzo',
    src: monzoColor,
    width: 55,
  },
  monzoWhite: {
    alt: 'Monzo',
    src: monzoWhite,
  },
  moveable: {
    alt: 'Moveable',
    src: moveable,
  },
  nhs: {
    alt: 'NHS',
    src: nhs,
  },
  nhsColor: {
    alt: 'NHS',
    src: nhsColor,
  },
  nhsWhite: {
    alt: 'NHS',
    src: nhsWhite,
  },
  oddbox: {
    alt: 'Oddbox',
    src: oddbox,
    width: 100,
  },
  onefinestayWhite: {
    alt: 'One Fine Stay',
    src: oneFineStayWhite,
  },
  onfidoWhite: {
    alt: 'Onfido',
    src: onfidoWhite,
  },
  otro: {
    alt: 'Otro',
    src: otro,
  },
  piLabs: {
    alt: 'Pi Labs',
    src: pilabsLogo,
    width: 130,
  },
  piTop: {
    alt: 'Pi-Top',
    src: piTop,
  },
  proper: {
    alt: 'Propercorn',
    src: proper,
    width: 80,
  },
  pwcColor: {
    alt: 'PWC',
    src: priceWaterHouseCoopersColor,
  },
  pwcWhite: {
    alt: 'PWC',
    src: priceWaterHouseCoopersWhite,
  },
  scg: {
    alt: 'Starwood Capital Group',
    src: scgLogo,
    width: 90,
  },
  secondHome: {
    alt: 'Second Home',
    src: secondHome,
    width: 150,
  },
  seedcamp: {
    alt: 'Seedcamp',
    src: seedcampLogo,
    width: 140,
  },
  shutterstock: {
    alt: 'Shutterstock',
    src: shutterstock,
    width: 160,
  },
  songkick: {
    alt: 'Songkick',
    src: songkick,
    width: 110,
  },
  songkickWhite: {
    alt: 'Songkick',
    src: songkickWhite,
    width: 110,
  },
  starlingColor: {
    alt: 'Starling Bank',
    src: starlingColor,
  },
  stubhub: {
    alt: 'StubHub',
    src: stubhub,
    width: 104,
  },
  stubhubWhite: {
    alt: 'StubHub',
    src: stubhubWhite,
  },
  taskRabbit: {
    alt: 'TaskRabbit',
    src: taskrabbit,
    width: 142,
  },
  techCrunch: {
    alt: 'Tech Crunch',
    src: techCrunchLogo,
    width: 64,
  },
  telefonica: {
    alt: 'Telefonica',
    src: telefonica,
    width: 130,
  },
  telefonicaWhite: {
    alt: 'Telefonica',
    src: telefonicaWhite,
    width: 130,
  },
  telegraph: {
    alt: 'The Telegraph',
    src: telegraphLogo,
    width: 172,
  },
  theCrownEstate: {
    alt: 'The Crown Estate',
    src: theCrownEstate,
    width: 110,
  },
  tobacoDock: {
    alt: 'Tobaco Dock',
    src: tobacoDock,
    width: 70,
  },
  tog: {
    alt: 'TOG - The Office Group',
    src: tog,
    width: 85,
  },
  togBlue: {
    alt: 'TOG - The Office Group',
    src: togBlue,
    width: 85,
  },
  trussle: {
    alt: 'Trussle',
    src: trussle,
    width: 128,
  },
  trustpilot: {
    alt: 'Trustpilot',
    src: trustpilotBlack,
    width: 142,
  },
  trustpilotColor: {
    alt: 'Trustpilot',
    src: trustpilot,
  },
  trustpilotWhite: {
    alt: 'Trustpilot',
    src: trustpilotWhite,
  },
  uncommon: { alt: 'Uncommon', src: uncommon },
  weWork: {
    alt: 'WeWork',
    src: weWork,
    width: 130,
  },
  wired: {
    alt: 'Wired',
    src: wiredLogo,
    width: 137,
  },
  worklife: {
    alt: 'Work.Life',
    src: worklife,
  },
  workspace: {
    alt: 'Workspace',
    src: workspace,
    width: 105,
  },
  wpp: {
    alt: 'WPP',
    src: wpp,
    width: 87,
  },
  wppWhite: {
    alt: 'WPP',
    src: wppWhite,
  },
};
